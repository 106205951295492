import config from "../config/config";

const {tokenKey} = config;

/**
 * returns true if logged in
 * @returns Bool {is logged in}
 */
export const isLoggedIn = ()=> window.localStorage.getItem(tokenKey)!== null;

/**
 * returns the token if logged in
 * @returns {string} the token
 */
export const getAuthToken = ()=> window.localStorage.getItem(tokenKey);

/**
 * logs the user out
 * @param {Function} callback the residue cleanup
 * @returns void
 */
export const logoutUser = (callback = ()=> null)=> {
    window.localStorage.removeItem(tokenKey);
    if (typeof callback === 'function') {
        callback();
    }
    // window.location.href = "/"
};