import axios from "axios";
import config from "../../config/config";
import { getAuthToken, isLoggedIn, logoutUser } from "../../helpers/auth.helper";

// REACT_APP_API_URL
const { apiURL } = config

const axiosInstance = axios.create({
  baseURL: apiURL,
  // timeout: 1000,
  // headers: { 'Authorization': `Bearer ${getAuthToken()}` }
});

axiosInstance
  .interceptors
  .request
  .use((config) => {

    if (getAuthToken()) {
      config.headers.Authorization = `Bearer ${getAuthToken()}`;
    }
    return config;
  }, (error) => Promise.reject(error));

axiosInstance.interceptors.response.use((response) => response,
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (isLoggedIn() && error.response.status === 401) {
      // Token is invalid or expired, try to refresh it
      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        throw new Error('No refresh token found');
      }

      try {
        const response = await axios.post(`${apiURL}/auth/refresh-tokens`, {
          refreshToken,
        });

        const newAccessToken = response.data.access.token;
        const newRefreshToken = response.data.refresh.token;

        localStorage.setItem('token', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        // Retry the original request with the new access token
        error.config.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(error.config);
      } catch (error) {
        console.log(error)
        // showSnackbar({}, () => logoutUser()).warning('Session expired. Please login');
        logoutUser();
        throw error;
      }

    }
    return Promise.reject(error);
  });

export default axiosInstance;