import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from 'react-image-magnify';
import { MONTH_DATE_YEAR_FORMAT } from '../../../constant';

import brush from "../../../assets/images/icons/digiArt.svg";

import CaretRight from "../../../assets/images/icons/CaretRight.svg";
import like2 from "../../../assets/images/icons/like2.svg";
import eye from "../../../assets/images/icons/eye.svg";
// import ethereum from "../../../assets/images/icons/ethereum.svg";
import dollar from "../../../assets/images/icons/dollar.svg";
import share from "../../../assets/images/icons/share.svg";
import like3 from "../../../assets/images/icons/like3.svg";


// import Goldfish from "../../../assets/images/artCarousel/Goldfish.png";
import artist from "../../../assets/images/artist.png";

// import art1 from "../../../assets/images/artItems/art1.png";
// import art2 from "../../../assets/images/artItems/art2.png";
// import art3 from "../../../assets/images/artItems/art3.png";
// import art4 from "../../../assets/images/artItems/art4.png";
// import art5 from "../../../assets/images/artItems/art5.png";
// import art6 from "../../../assets/images/artItems/art6.png";

import { ScrollToTopOnMount } from "../../../components/scrollToTop/scrollToTop";


import "./PieceDetails.css"

import S3ImageViewerWithFallback from '../../../components/awsS3ImageViewer/ImgViewerWithFallback';
import RichTextViewer from '../../../components/rich-text-editor/TextViewer';

const ChipButton = [
  {
    label: 'Darkness outlet',
  },
  {
    label: 'Possibilities',
  },
  {
    label: 'Psychedelic',
  },

]
const settingsTagSlider = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 1500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 2,
  autoplay: false,
  autoplaySpeed: 0,
  zoom: true,
  pauseOnHover: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        pauseOnHover: true,
        arrows: false,
        slidesToShow: 2,
        infinite: true,
        autoplay: true,
        variableWidth: true,
      }
    }
  ]
};

const settingsCategory = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 3500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 0,
  zoom: true,
  pauseOnHover: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        slidesToShow: 1,

      }
    }
  ]
};

const CategoryButton = [
  {
    icon: brush,
    label: 'Pure static digital art',
  },

]


const settingsMagnifySlider = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1500,
  autoplaySpeed: 3500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 1,
  autoplay: true,
  zoom: true,
  pauseOnHover: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: false,

      }
    }
  ]
};




const PieceDetails = () => {


  const location = useLocation();
  const selectedArt = location.state?.element || {};

  const [isExpanded, setIsExpanded] = useState(false);
  const [isContentLong, setIsContentLong] = useState(false);
  const contentRef = useRef(null);  // Reference to the content container
  const toggleContent = () => {
    setIsExpanded((prev) => !prev);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const checkContentHeight = () => {
      if (contentRef.current) {
        setIsContentLong(contentRef.current.scrollHeight > 80);
      }
    };

    // Check content height on component mount
    checkContentHeight();

    // Optionally, you could also listen for window resize events
    window.addEventListener('resize', checkContentHeight);

    return () => {
      window.removeEventListener('resize', checkContentHeight);
    };
  }, []);



  ScrollToTopOnMount();

  return (
    <div className="page-main-wrapper art-view-wrapper f-wrp">
      <div className='container'>
        <div className='back-btn-wrp f-wrp'>
          <Link to={'/art-gallery'}><img src={CaretRight} alt='back' /> Back</Link>
        </div>
        <div className='art-main-wrapper f-wrp'>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className='left-side-wrp f-wrp'>
                <div className='slider-sec MagnifySlider f-wrp'>
                  <div className='link-extract-box'>
                    <S3ImageViewerWithFallback fileKey={selectedArt.image} />
                  </div>



                  {/* <Slider {...settingsMagnifySlider}>

                    <div className="each-slider-items f-wrp">

                      <ReactImageMagnify {...{
                        enlargedImagePosition: 'over',
                        isHintEnabled: false,
                        hintTextMouse: 'hover to zoom',
                        shouldHideHintAfterFirstActivation: false,
                        style: {
                          objectFit: 'contain'
                        },
                        smallImage: {
                          alt: 'Wristwatch by Ted Baker London',
                          isFluidWidth: true,
                          src: `${selectedArt.image}`,
                          sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                        },
                        largeImage: {
                          src: `${selectedArt.image}`,
                          width: 1200,
                          height: 1800,
                        }
                      }} />
                    </div>

                  </Slider> */}


                  {/* <div className='art-details f-wrp'>
                    <p><img src={like2} alt='like' /> 368 Likes  &nbsp;&nbsp; <img src={eye} alt='like' /> 4.3k Views</p>
                  </div> */}
                </div>
                <div className='artist-block f-wrp'>
                  <span><S3ImageViewerWithFallback fileKey={selectedArt.artistImage} /></span>
                  <div className='artist-details'>
                    {selectedArt.artistName && <h4>{selectedArt.artistName}</h4>}
                    {selectedArt.shortDescription && <p>{selectedArt.shortDescription}</p>}
                  </div>
                </div>

                {selectedArt.categories?.length > 0 ? <div className='category-slider f-wrp'>
                  <Slider {...settingsCategory}>
                    {selectedArt.categories?.map((element, key) => (
                      <div className="each-categry-btn f-wrp" key={key}>
                        <span className="chip-btn"><img src={element.icon} alt="icon" /> {element.label}</span>
                      </div>
                    ))}
                  </Slider>
                </div> : null}

                {selectedArt.tags?.length > 0 ? <div className='art-tag-btn f-wrp'>
                  <Slider {...settingsTagSlider}>
                    {selectedArt.tags?.map((element, key) => (
                      <div key={key}>
                        <span className="chip-btn">{element.label}</span>
                      </div>
                    ))}
                  </Slider>
                </div> : null}



                {/* 

                <div className='category-slider f-wrp'>
                  <Slider {...settingsCategory}>
                    {CategoryButton.map((element, key) => (
                      <div className="each-categry-btn f-wrp" key={key}>
                        <span className="chip-btn"><img src={element.icon} alt="icon" /> {element.label}</span>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className='art-tag-btn f-wrp'>

                  <Slider {...settingsTagSlider}>
                    {ChipButton.map((element, key) => (
                      <div key={key}>
                        <span className="chip-btn">{element.label}</span>
                      </div>
                    ))}
                  </Slider>
                </div> */}






                {/* 
                <div className='image-detail-wrp f-wrp'>
                  <h5>Image detail</h5>
                  <p>Includes printed copy</p>
                  <p><b>Image size</b>4092 x 2895px  692.79 KB</p>
                  <p>Last piece available</p>
                </div> */}

                {/* <div className='price-detail-sec f-wrp'>
                  <h3><img src={dollar} alt='dollar' /> {selectedArt.price}
                    <b>or</b> <img src={ethereum} alt='ethereum' /> 0,0013
                  </h3>
                </div>

                <div className='btn-wrap f-wrp'>
                  <ul>
                    <li><span className="block-btn initial-btn">Work with artist</span></li>
                    <li><span className="block-btn buy-btn">Buy art</span></li>
                  </ul>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className='right-content-sec f-wrp'>
                <div className='profile-card-wrp f-wrp'>
                  <div className='profile-card-header f-wrp'>
                    <h2>{selectedArt.title}<span className='share-btn-box'><Button className='icon'><img src={like3} alt='like' /></Button> <Button className='icon'><img src={share} alt='share' /></Button></span></h2>
                    <p>{selectedArt.artistName && <>by <h6 style={{ display: 'inline' }}>{selectedArt.artistName}</h6> &nbsp;</>}<i>{dayjs(selectedArt.createdAt).format(MONTH_DATE_YEAR_FORMAT)}</i></p>
                  </div>

                  <div className='profile-card-con f-wrp'>
                    <div className='f-wrp low-text'>
                      <p ref={contentRef}
                        // className={`content-text ${isExpanded ? 'expanded' : ''}`}

                        className={`content-text ${isExpanded ? 'expanded' : ''}`}

                        style={{ maxHeight: isExpanded ? 'none' : '80px', overflow: 'hidden' }}

                      >
                        <RichTextViewer richText={selectedArt?.description} />
                      </p>
                    </div>
                    {/* <button type='button' className='plan-btn' onClick={toggleContent}>
                      {isExpanded ? 'Read less' : 'Read more'}
                    </button> */}

                    {isContentLong && (
                      <button
                        type='button'
                        className='plan-btn'
                        onClick={() => setIsExpanded(prev => !prev)}
                      >
                        {isExpanded ? 'Read Less' : 'Read More'}
                      </button>
                    )}
                  </div>

                </div>

                {/* <div className='art-gallery-wrp f-wrp'>
                  <h5>More by Artist</h5>
                  <ul>
                    <li><span><img src={art1} alt='gallery' /></span></li>
                    <li><span><img src={art2} alt='gallery' /></span></li>
                    <li><span><img src={art3} alt='gallery' /></span></li>
                    <li><span><img src={art4} alt='gallery' /></span></li>
                    <li><span><img src={art5} alt='gallery' /></span></li>
                    <li><span><img src={art6} alt='gallery' /></span></li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          <div className="project-switch-wrp f-wrp" style={{ paddingBottom: '35px' }}>
            <ul>
              <li>
                <Link to={"/art-gallery"}>
                  <label>Previous</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/art-gallery"}>
                  <label>Next</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
            </ul>
          </div>


        </div>
      </div>
    </div>
  );
};

export default PieceDetails;
